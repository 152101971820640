<script lang="ts">
  import { page } from "$app/stores";

  import { Monitor } from "lucide-svelte";

  import { analytics } from "$lib/analytics";
  import { user } from "$lib/user";

  export let next: string = "/home";

  $: href = "/login?next=" + encodeURIComponent(next);

  export let vertical: boolean = false;
  let showLoginButton: boolean;
  let showSignupButton: boolean;
  let showDashboard: boolean;
  $: showLoginButton =
    !$user?.logged_in && !$page.url.pathname.startsWith("/login");
  $: showSignupButton =
    !$user?.logged_in && !$page.url.pathname.startsWith("/signup");
  $: showDashboard = $user?.logged_in ?? false;
</script>

<div class={vertical ? "flex flex-col gap-y-3" : "flex gap-x-3"}>
  {#if showLoginButton}
    <a
      {href}
      class="nav-link text-primary ring-primary/50 hover:bg-primary/20 active:bg-primary/20"
      class:py-2={vertical}
      on:click>Log In</a
    >
  {/if}
  {#if showSignupButton}
    <a
      class="nav-link border border-primary -m-px text-primary ring-primary/50 bg-primary/10 hover:bg-primary/20 active:bg-primary/25"
      class:py-2={vertical}
      href="/signup"
      on:click={() => analytics.track("Signup Button Clicked")}>Sign Up</a
    >
  {/if}
  {#if showDashboard}
    <div class="flex">
      <a
        class="nav-link text-primary ring-primary/50 hover:bg-primary/20 active:bg-primary/20"
        href="/home"
        on:click
      >
        <Monitor size={16} />
        <span class="ml-2">Dashboard</span>
      </a>
    </div>
  {/if}
</div>
